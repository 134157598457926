import { ISidenavItem } from '../components/cde-sidenav/models/sidenav.model';

export const sidenavItems: ISidenavItem[] = [
  {
    text: 'dashboard.widgets.notifications.title',
    route: '/notifications',
    hide: true,
  },
  {
    text: 'settings.legal_texts',
    route: '/privacy',
    hide: true,
  },
  {
    text: 'settings.support',
    route: '/support',
    hide: true,
  },
  {
    text: 'left_sidenav.dashboard',
    route: '/dashboard',
    svgIcon: {
      iconId: '648421',
      iconName: '',
      namespace: 'cc',
      size: 24,
      style: 'linear',
    },
    translate: true,
  },
  {
    text: 'left_sidenav.machines',
    route: '/machines',
    svgIcon: {
      iconId: '000131',
      iconName: '',
      namespace: 'cc',
      size: 24,
    },
    translate: true,
  },
  {
    text: 'left_sidenav.analytics',
    route: '/analytics',
    svgIcon: {
      iconId: '648429',
      iconName: '',
      namespace: 'cc',
      size: 24,
    },
    translate: true,
  },
  {
    text: 'left_sidenav.config',
    route: '/configurations',
    svgIcon: {
      iconId: '017978',
      iconName: '',
      size: 24,
    },
    translate: true,
  },
  {
    text: 'left_sidenav.shops',
    route: '/shops',
    svgIcon: {
      iconId: '648469',
      iconName: '',
      size: 24,
    },
    translate: true,
  },
  // {
  //   text: 'left_sidenav.training',
  //   route: undefined,
  //   svgIcon: {
  //     iconName: 'trainer_teacher_training_workshop',
  //     size: 24,
  //   },
  //   translate: true,
  // },
  {
    text: 'settings.connection_manager',
    route: '/connections',
    svgIcon: {
      iconId: '001612',
      iconName: '',
      size: 24,
    },
    translate: true,
  },
  {
    text: 'operations.title',
    route: '/operations',
    svgIcon: {
      iconId: '001362',
      iconName: '',
      size: 24,
    },
    translate: true,
  },
  {
    text: 'license.title',
    route: '/license',
    svgIcon: {
      iconId: '001414',
      iconName: '',
      size: 24,
    },
    translate: true,
    removeForDemoOrg: true,
  },
  {
    text: 'file_transfer.title',
    route: '/file-transfer',
    svgIcon: {
      iconId: '659779',
      iconName: '',
      size: 24,
    },
    translate: true,
    removeForDemoOrg: true,
  },
];

export const goBackNavItems: ISidenavItem[] = [
  { text: 'left_sidenav.dashboard', route: '/dashboard' },
  { text: 'machine_details.machine_overview', route: '/machines/overview' },
  { text: 'go_back_nav.machine_details', route: '/machines/details' },
  { text: 'machine_details.machine_overview', route: '/machines' },
  { text: 'go_back_nav.analysis_reports', route: '/analytics/reports' },
  { text: 'go_back_nav.analysis_overview', route: '/analytics/details' },
  { text: 'go_back_nav.analysis_create_report', route: '/analytics/createReport' },
  { text: 'analytics.machine_comparison.title', route: '/analytics/comparison' },
  { text: 'analytics.title', route: '/analytics' },
  { text: 'left_sidenav.config', route: '/configurations' },
  { text: 'left_sidenav.shops', route: '/shops' },
  { text: 'file_transfer.new_transfer', route: '/file-transfer' },
  { text: 'settings.connection_manager', route: '/connections' },
  { text: 'settings.legal_texts', route: '/privacy' },
  { text: 'settings.support', route: '/support' },
  { text: 'left_sidenav.dashboard', route: '/dashboard' },
  { text: 'file_transfer.new_transfer', route: '/file-transfer/new-file-transfer' },
  { text: 'file_transfer.title', route: '/file-transfer' },
  { text: 'operations.title', route: '/operations' },
  { text: 'settings.connection_manager', route: '/connections' },
  { text: 'left_sidenav.shops', route: '/shops' },
  { text: 'go_back_nav.migration', route: '/migration' },
];
